<template>
  <div class="index2">
    <headbread></headbread>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import headbread from '@/components/breadcrumb.vue';
export default {
  provide(){
      return{
        reload:this.reload
      }
  },
  components: {
    headbread
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  methods: {
    reload(){
        this.isRouterAlive = false;
        this.$nextTick(function () {
          this.isRouterAlive = true;
        });
      }
  },
}
</script>

<style  lang="less" scoped>
.index2{
  height: 100%;
}
</style>